
/* You can add global styles to this file, and also import other style files */
@config "../tailwind.config.js"; 
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito Sans";
}
.text_nunito{
  font-family: "Nunito Sans";
}
body {
  margin: 0 !important;
  overflow-x: hidden;
}
.body-without-overflow-hidden {
  overflow-x: unset !important;
}
.modal-open {
  overflow: hidden;
}
@media screen and (min-width: 1200px) {
  .page_container {
    max-width: 1170px;
  }
}
@media screen and (min-width: 992px) {
  .page_container {
    max-width: 960px;
  }
}
@media screen and (max-width: 769px) {
  /* html{
    overflow-x: hidden;
  } */

  [data-aos^="fade"][data-aos^="fade"] {
    opacity: 1 !important;
  }
  [data-aos="slide-up"] {
    transform: none !important;
  }
  [data-aos="fade-left"] {
    transform: none !important;
  }
  [data-aos="fade-right"] {
    transform: none !important;
  }
  [data-aos="zoom-in"] {
    transform: none !important;
    opacity: 1 !important;
  }
  .page_container {
    max-width: 90% !important;
  }
  * {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  *:focus {
    outline: none !important;
  }
}
@media screen and (max-width: 480px) {
  .page_container {
    max-width: 90% !important;
  }
}
.page_container {
  max-width: 1170px;
  margin: 0px auto;
}
.JSB-flip-clock-number{
  width: 30px !important;
  height: 50px !important;
}
.JSB-flip-clock-number-digit {
  line-height: 52px !important;
}
.JSB-flip-clock-card-value {
  font-size: 24px !important;
}
.flip-clock-divider {
  width: 20px !important;
  height: 100px !important;
}
.JSB-flip-clock-dot-top {
  top: 15px !important;
}
.JSB-flip-clock-dot-bottom {
  bottom: 55px !important;
}
.JSB-flip-clock {
  position: absolute;
  left: 115px;
  top: 115px;
}
@media screen and (max-width: 615px) {
  .JSB-flip-clock {
    left: 90px;
  }
  .timer {
    zoom: 0.7 !important;
  }
}
/* written by JR */
body.show_pop .modal_faq
{
  display: block !important;
  opacity:1 !important;
}

.text-link{
  position: relative;
  display: inline-block;
}

 /* Styling the animated underline */
 .text-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2.5px; /* Positioning the line just below the text */
  width: 0;
  height: 2px; /* Height of the line */
  border-radius: 4px;
  background-color: currentColor;
  transition: width 0.3s ease; /* Duration and timing of animation */
}

/* On hover, expand the line width */
.text-link:hover::after {
  width: 100%;
}
